import Footer from "components/laylouts/footer/Footer";
import TextButton from "components/widgets/text-Button/TextButton";
import React from "react";
import { BtnTitles } from "utils/constants/constants";

const QuestionDetailsFooter = (props) => {
  const {
    location,
    selectedActions,
    selectedData,
    shouldDisableAction,
    onActionStatusHandler,
    hasAddEditAccess,
    hasTranslationAccess,
    onIconEditHandler,
    onIconDelete,
  } = props;
  return (
    <Footer>
      <div className="d-flex mx-3 gap-2">
        {selectedActions?.length > 0
          ? selectedActions?.map((ele) => {
              return (
                <TextButton
                  title={ele?.label}
                  rounded={false}
                  shouldDisable={
                    selectedData?.status === "PUBLISHED" ||
                    shouldDisableAction(ele)
                  }
                  clickHandler={(e) => onActionStatusHandler(e, ele)}
                />
              );
            })
          : null}
      </div>
      <div className="d-flex table_manage_question_footer">
        {hasAddEditAccess() || hasTranslationAccess() ? (
          <div
            className={`${"footer_buttons"} ${
              selectedData?.isEditDisable ? "qd-action-state-btn-color" : ""
            } `}
            onClick={(e) => onIconEditHandler(e)}
          >
            <TextButton
              title={BtnTitles.EDIT}
              rounded={false}
              shouldDisable={selectedData?.isEditDisable}
            />
          </div>
        ) : null}
        {hasAddEditAccess() ? (
          <div
            className={`${"footer_buttons"} ${
              selectedData?.isEditDisable ? "qd-action-state-btn-color" : ""
            } `}
            onClick={(e) => onIconDelete(e, "Delete", location?.state?.id)}
          >
            <TextButton
              title={BtnTitles.DELETE}
              rounded={false}
              className={
                selectedData?.isEditDisable ? "qd-action-state-btn-color" : ""
              }
              shouldDisable={selectedData?.isEditDisable}
            />
          </div>
        ) : null}
      </div>
    </Footer>
  );
};

export default QuestionDetailsFooter;
